export const data = [
	{
		to: '/',
		text: 'About',
		id: 'about',
	},
	{
		to: '/pricing',
		text: 'Pricing',
	},
	{
		to: '/contact',
		text: 'Contact',
	},
];
